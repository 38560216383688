import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Law from "../components/law";

const containerVariants = {
  hidden: {
    opacity: 0,
    y: "50vh",
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
    },
  },
  next: {
    y: "-100vh",
    transition: { ease: "easeInOut", duration: 0.8 },
  },
  back: {
    y: "+100vh",
    transition: { ease: "easeInOut", duration: 0.8 },
  },
};

const Slide5 = ({ screen }) => {
  const [exit, setExit] = useState("next");
  return (
    <motion.div
      className="about-container"
      variants={containerVariants}
      initial="hidden"
      animate="show"
      exit={exit}
    >
      <div
        style={{
          height: "100vh",
          width: "80%",
          margin: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{ padding: 0 }}>
            <div style={{ textAlign: screen > 600 ? "left" : "center" }}>
              <motion.h1
                initial={{ y: 10 }}
                animate={{
                  y: -10,
                  transition: {
                    duration: 1,
                    ease: "easeIn",
                    repeat: Infinity,
                    repeatType: "reverse",
                  },
                }}
                onClick={() => {
                  setExit("back");
                }}
              >
                <Link className="link" to="/slide4">
                  &#8657;
                </Link>
              </motion.h1>
            </div>
            <div
              style={{
                display: screen > 600 ? "grid" : "inline-block",
                alignItems: "center",
                minHeight: "70vh",
              }}
              data-aos="fade-up"
              data-aos-offset="500"
            >
              <div
                style={{
                  color: "white",
                  fontSize: screen > 600 ? "3.5rem" : "2rem",
                  lineHeight: "2.5rem",
                  fontFamily: "Champagne",
                  textAlign: screen > 600 ? "left" : "center",
                }}
              >
                Toda esta propuesta amparada en la ley de vivienda
                <span style={{ color: "#77b516" }}> 546 de 1999</span>
              </div>
            </div>
            <div
              style={{
                textAlign: screen > 600 ? "left" : "center",
                zIndex: 200,
              }}
            >
              <motion.h1
                initial={{ y: -10 }}
                animate={{
                  y: 10,
                  transition: {
                    duration: 1,
                    ease: "easeIn",
                    repeat: Infinity,
                    repeatType: "reverse",
                  },
                }}
                onClick={() => {
                  setExit("next");
                }}
              >
                <Link className="link" to="/slide6">
                  &#8659;
                </Link>
              </motion.h1>
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: 0, zIndex: screen > 600 ? 2 : -1 }}>
            <Law screen={screen} />
          </Grid>
        </Grid>
      </div>
    </motion.div>
  );
};

export default Slide5;
