import React from "react";
import { motion } from "framer-motion";

const svgVariants = {
  hidden: { y: "100px" },
  show: {
    y: "250px",
    transition: {
      delay: 1,
      duration: 3,
      ease: "easeInOut",
    },
  },
};
const pathVariants = {
  hidden: {
    pathLength: 0,
  },
  show: {
    pathLength: 1,
    transition: {
      delay: 1,
      duration: 6,
      ease: "easeInOut",
    },
  },
};
const Intereses = () => {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16256 2370.66"
      id="interesesSvg"
      variants={svgVariants}
      initial="hidden"
      animate="show"
    >
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M12736.39 1155.81l1409.54 0 0 109.67 -1409.54 0 0 835.22 1519.26 0 0 109.67 -1637.42 0 0 -2058.51 1637.42 0 0 118.11 -1519.26 0 0 885.84zm-168.8 -1029.26l0 2134.44 1730.27 0 0 -210.91 -1519.26 0 0 -733.98 1417.97 0c0,-121.59 18.71,-210.91 -25.32,-210.91l-1392.65 0 0 -793.04 1519.26 0 0 -210.91 -1704.95 0c-19.45,0 -25.32,5.87 -25.32,25.31z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M8794.79 1155.81l1409.52 0 0 109.67 -1409.52 0 0 835.22 1519.24 0 0 109.67 -1637.41 0 0 -2058.51 1637.41 0 0 118.11 -1519.24 0 0 885.84zm-168.82 -1029.26l0 2134.44 1730.27 0 0 -210.91 -1519.25 0 0 -733.98 1417.97 0c0,-121.59 18.71,-210.91 -25.33,-210.91l-1392.64 0 0 -793.04 1519.25 0 0 -210.91 -1704.94 0c-19.45,0 -25.33,5.87 -25.33,25.31z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M4785.64 1155.81l1417.97 0 0 109.67 -1392.65 0c-19.44,0 -25.32,5.87 -25.32,25.31l0 809.91 1519.25 0 0 109.67 -1637.41 0 0 -2058.51 1637.41 0 0 118.11 -1519.25 0 0 885.84zm-160.36 1105.18l1730.26 0 0 -210.91 -1519.26 0 0 -733.98 1409.54 0 0 -210.91 -1409.54 0 0 -793.04 1519.26 0c0,-121.59 18.72,-210.91 -25.32,-210.91l-1704.94 0 0 2159.75z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M700.54 2218.81l-118.16 0 0 -2100.7c23.11,15.46 34.15,28.9 50.65,50.61l196.62 225.3c20.33,24.18 30.33,30.38 51.71,57.99l454.28 516.12c23.48,23.62 29.48,36.5 50.61,59.08l295.86 337.02c32.78,39.14 71.33,74.95 104.74,114.66 20.19,24 24.31,31.16 46.9,54.35 71.55,73.46 218.75,257.61 303.84,345.92 26.81,27.82 21.85,43.46 56.88,52.81l0 -1780.11c149.04,0 118.17,-37.13 118.17,236.22l0 1856.04c-24.62,-8.91 -17.62,-11.55 -52.88,-48.39l-754.9 -856.81c-20.23,-24.15 -24.27,-31.13 -46.89,-54.36 -22.24,-22.83 -27.38,-35.95 -50.51,-59.19l-651.39 -740.93c-17.83,-23.14 -33.36,-39.33 -55.53,-54.18l0 1788.55zm-160.36 42.18l211 0 0 -1704.18c32.32,21.63 203.97,230.59 246.81,276.36 22.68,24.23 29.69,33.06 51.09,58.62l151.65 169c22.31,22.91 27.31,36.22 50.41,59.29 23.14,23.1 27.46,28.94 47.41,53.85l1056.29 1196.74 0 -2261 -211.01 0 0 1704.18c-15.26,-11.17 -30.69,-34.58 -48.58,-52.67l-249.04 -282.58c-22.3,-22.9 -27.31,-36.22 -50.41,-59.28l-949.28 -1075.91c-21.41,-25.56 -28.42,-34.39 -51.09,-58.61l-201.05 -229.31c-20.18,-26.21 -28.25,-38.12 -54.2,-55.49l0 2260.99z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M15116.56 396.52c0,108.51 119.82,165.86 189.25,207.35 33.56,20.06 54.78,27.54 91.72,43.31 34.26,14.61 59.44,30.33 91.57,43.45l274.6 130.48c102.81,55.17 232.18,154.71 298.35,241.72 168.92,222.17 188.75,537.34 67.53,784.96 -121.52,248.27 -398.9,446.95 -692.29,446.95 -270.03,0 -438.33,-71.12 -601.38,-234.1 -101.28,-101.23 -174.37,-221.7 -209,-364.78 -9.92,-40.96 -24.15,-121.38 -25.2,-168.85l118.16 0c0,177.96 80.59,326.92 194.98,446.28 192.16,200.54 475.8,263.36 749.26,160.08 205.37,-77.56 423.09,-326.26 423.09,-572.61 0,-162.88 5.4,-232.03 -83.82,-380.23 -44.37,-73.71 -102.51,-130.72 -172.94,-181.48 -323.53,-233.17 -791.51,-274.62 -829.08,-589.19 -26.53,-222.13 255.93,-450.03 545.87,-308.85 40.07,19.51 106.1,65.54 117.95,109.9 -31.98,16.92 -68.78,26.59 -101.29,42.18 -25.9,-17.33 -41.08,-38.13 -73.31,-53.26 -54.76,-25.72 -104.09,-22.66 -163.01,-22.66 -59.99,0 -111.04,39.08 -143.52,67.45 -37.77,33 -67.49,80.9 -67.49,151.9zm-160.37 -16.88c0,177.12 91.22,259.3 231.42,342.37 152.18,90.18 345.41,155.31 461.08,222.5 206.71,120.07 396.3,241.33 396.3,557.19 0,507.48 -575.52,807.03 -997.79,524.91l-84.65 -67.25c-26.37,-22.95 -44.78,-49.67 -66.61,-76.84 -102.07,-127.03 -125.43,-242.07 -125.43,-406.13l-211.01 0c0,158.88 11.12,264.88 86.13,403.24 48.85,90.11 102.09,163.44 173.24,231.78l113.66 88.87c575.87,383.75 1323.47,-40.22 1323.47,-698.58 0,-816.48 -1088.8,-846.3 -1088.8,-1096.75 0,-65.01 7.31,-96 58.74,-135.33 115.09,-88.04 257.47,-52.4 321.07,42.53 23.66,-12.52 177.25,-65.78 177.25,-84.37 0,-101.44 -207.32,-243.99 -445.13,-208.72 -161.15,23.92 -322.94,171.76 -322.94,360.58z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M11174.94 379.64c0,146.83 164.31,215.59 286.87,270.08 32.08,14.27 55.84,28.61 91.73,43.29 28.06,11.47 66.17,27.05 94.19,40.85 56.65,27.89 124.88,58.65 178.81,91.22 276,166.7 437.2,329.34 437.2,701.93 0,404.79 -363.96,767.73 -768.06,767.73 -270.03,0 -438.34,-71.12 -601.39,-234.1 -135.03,-134.97 -234.2,-338.56 -234.2,-533.63l118.16 0c0,156.1 68.68,309.3 166.66,415.54 52.56,56.98 51.92,61.62 127.39,117.32 146.86,108.37 345.44,146.13 525.65,109.32 47.23,-9.65 87.98,-21.13 130.63,-38.16 38.25,-15.27 76.49,-37.48 109.43,-59.36 168.56,-111.9 307.57,-314.3 307.57,-527.79 0,-191 -26.19,-326.63 -158.14,-457.8 -334.97,-332.99 -1095.28,-374.31 -899.62,-827.27 76.81,-177.83 327.92,-254.93 517.85,-157.84 38.96,19.91 106.15,66 117.9,109.94 -31.42,15.07 -69.87,27.11 -101.29,42.18 -94.33,-63.14 -69.84,-75.92 -236.33,-75.92 -95.98,0 -211.01,103.32 -211.01,202.47zm-160.36 -16.87c0,190.47 72.82,265.26 231.42,359.24 322.41,191.06 857.38,276.45 857.38,779.69 0,523.57 -605.02,809.31 -1012.96,514.76 -149.16,-107.69 -263.33,-291.83 -261,-481.54 0.93,-75.28 16.74,-58.53 -211.54,-58.53l0 84.37c0,293.79 242.28,605.56 501.48,713.61 339.78,141.65 698.26,66.33 939.71,-175.77 274.98,-275.74 344.09,-698.8 133.43,-1015.01 -70.04,-105.12 -177.09,-200.82 -283.63,-264.86 -206.6,-124.19 -341.19,-163.63 -548.37,-270.23 -83.26,-42.84 -134.92,-84.03 -134.92,-168.86 0,-156.13 269.6,-232.01 379.82,-67.49 23.65,-12.52 177.24,-65.78 177.24,-84.37 0,-61.51 -109.58,-147.86 -157.93,-171.15 -78.25,-37.69 -178.75,-51.28 -279.7,-38.51 -156.85,19.83 -330.43,171.55 -330.43,344.65z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M7756.63 1569.19c7.02,26.27 30.09,44.18 61.69,81.76 48.5,57.68 452.6,525.71 461.61,559.42 -152.99,0 -128.82,25.03 -224.3,-96.39 -49.15,-62.5 -96.98,-114.22 -145.9,-174.75 -36.84,-45.62 -279.37,-320.28 -288.15,-353.16l-709.27 -0.3c-37.58,-0.61 -75.68,-9.96 -75.68,25.61l0 598.99 -109.71 0 0 -2058.51 818.7 0c326.63,0 575.63,146.92 700.64,396.42 135.49,270.41 89.33,559.27 -100.11,794.4 -38.47,47.76 -109.55,105.26 -165.06,138.73 -44.58,26.87 -166.28,82.95 -224.46,87.78zm-1080.36 691.8l211.01 0 0 -624.3 692.1 0c36.84,0 317.28,362.46 365.46,419.3l100.94 118.47c15.43,17.32 19.78,24.77 34.3,41.63 20.16,23.39 22.88,14.34 31.04,44.9l270.09 0c-7.81,-29.23 -107.47,-140.28 -129.22,-166.12 -24.22,-28.78 -46.45,-52.38 -69.75,-82.14l-69.24 -82.64c-48.86,-56.05 -89.93,-108.94 -138.81,-164.97 -19.93,-22.85 -125.84,-143.2 -133.16,-170.62 75.94,-17.67 151.56,-63.55 209.51,-102.73 463.27,-313.22 419.05,-1055.18 -113.7,-1309.13 -81.06,-38.63 -191.1,-72.97 -306.81,-72.97l-953.76 0 0 2151.32z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M2650.25 151.86l1679.61 0 0 118.11 -784.93 0 0 1940.4 -109.73 0 0 -1940.4 -784.95 0 0 -118.11zm-42.2 160.29l776.51 0 0 1948.84 211.01 0 0 -1948.84 776.5 0 0 -202.48 -1764.02 0 0 202.48z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M7613.14 1425.77l-725.86 0 0 -1113.62c182.94,0 751.32,-19.89 894.82,33.59 65.83,24.54 145.92,65.89 192.04,111.77 416.74,414.57 74.63,968.26 -361,968.26zm-776.51 25.31c0,19.44 5.88,25.31 25.32,25.31l692.11 0c200.78,0 310.55,-45.6 452.27,-155.35 48.71,-37.73 101.88,-115.69 132.88,-170.89 166.15,-295.9 16.92,-676.25 -267.02,-818.53 -62.8,-31.47 -159.83,-61.65 -250.61,-61.65l-784.95 0 0 1181.11z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M50.64 151.86l118.16 0 0 2058.51 -118.16 0 0 -2058.51zm-50.64 2109.13l211.01 0 0 -2151.32 -211.01 0 0 2151.32z"
      />
    </motion.svg>
  );
};

export default Intereses;
