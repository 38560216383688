import React from "react";
import { useSpring, animated } from "react-spring";
import "../css/law.css";

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 6}px,${y / 8 + 140}px,0)`;
const trans3 = (x, y) => `translate3d(${x / 6 + 100}px,${y / 6 + 180}px,0)`;
const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5 + 240}px,0)`;

const Slide5 = ({ screen }) => {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));
  return (
    <div
      class="containerLaw"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <animated.div
        class="card51"
        style={{
          transform: props.xy.interpolate(trans1),
          marginTop: screen > 600 ? 0 : -450,
          zIndex: -4,
        }}
      />
      <animated.div
        class="card52"
        style={{
          transform: props.xy.interpolate(trans2),
          marginTop: screen > 600 ? 0 : -450,
		  zIndex: -3,
        }}
      />
      <animated.div
        class="card53"
        style={{
          transform: props.xy.interpolate(trans3),
          marginTop: screen > 600 ? 0 : -450,
		  zIndex: -2,
        }}
      />
      <animated.div
        class="card54"
        style={{
          transform: props.xy.interpolate(trans4),
          marginTop: screen > 600 ? 0 : -450,
		  zIndex: -1,
        }}
      />
    </div>
  );
};

export default Slide5;
