import React from "react";
import { useSpring, animated } from "react-spring";
import "../css/proposal.css";

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10 + 30}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 6}px,${y / 8 + 160}px,0)`;
const trans3 = (x, y) => `translate3d(${x / 6 - 105}px,${y / 6 + 200}px,0)`;
const trans4 = (x, y) => `translate3d(${x / 3.5 + 110}px,${y / 3.5 + 100}px,0)`;

const Slide4 = ({ screen }) => {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));
  return (
    <div
      class="containerProposal"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <animated.div
        class="card41"
        style={{
          transform: props.xy.interpolate(trans1),
          marginTop: screen > 600 ? 0 : 150,
        }}
      />
      <animated.div
        class="card43"
        style={{
          transform: props.xy.interpolate(trans3),
          marginTop: screen > 600 ? 0 : 150,
        }}
      />
      <animated.div
        class="card42"
        style={{
          transform: props.xy.interpolate(trans2),
          marginTop: screen > 600 ? 0 : 150,
        }}
      />
      <animated.div
        class="card44"
        style={{
          transform: props.xy.interpolate(trans4),
          marginTop: screen > 600 ? 0 : 150,
        }}
      />
    </div>
  );
};

export default Slide4;
