import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Proposal from "../components/proposal";

const containerVariants = {
  hidden: {
    opacity: 0,
    y: "50vh",
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
    },
  },
  next: {
    y: "-100vh",
    transition: { ease: "easeInOut", duration: 0.8 },
  },
  back: {
    y: "+100vh",
    transition: { ease: "easeInOut", duration: 0.8 },
  },
};

const titleVariants = {
  hidden: {
    opacity: 0,
    y: "-200px",
  },
  show: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 3,
      ease: "easeInOut",
    },
  },
};

const Slide4 = ({ screen }) => {
  const [exit, setExit] = useState("next");
  return (
    <motion.div
      className="about-container"
      variants={containerVariants}
      initial="hidden"
      animate="show"
      exit={exit}
    >
      <motion.div
        style={{
          position: "absolute",
          fontSize: screen > 600 ? "2rem" : "1rem",
          fontFamily: "Champagne",
          width: "100%",
          textAlign: "center",
        }}
        variants={titleVariants}
        initial="hidden"
        animate="show"
      >
        Por eso le presentamos la propuesta:{" "}
        <span style={{ color: "#77b516" }}>
          REDUCCION DE CREDITO HIPOTECARIO
        </span>
      </motion.div>
      <div
        style={{
          height: "100vh",
          width: "80%",
          margin: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{ padding: 0 }}>
            <Proposal screen={screen} />
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ textAlign: screen > 600 ? "right" : "center" }}>
              <motion.h1
                initial={{ y: 10 }}
                animate={{
                  y: -10,
                  transition: {
                    duration: 1,
                    ease: "easeIn",
                    repeat: Infinity,
                    repeatType: "reverse",
                  },
                }}
                onClick={() => {
                  setExit("back");
                }}
              >
                <Link className="link" to="/slide3">
                  &#8657;
                </Link>
              </motion.h1>
            </div>
            <div
              style={{
                display: screen > 600 ? "grid" : "inline-block",
                alignItems: "center",
                minHeight: "70vh",
                textAlign: screen > 600 ? "right" : "center"
              }}
              data-aos="fade-up"
              data-aos-offset="500"
            >
              <div
                style={{
                  color: "white",
                  fontSize: screen > 600 ? "2.5rem" : "1.5rem",
                  lineHeight: "2rem",
                  textAlign: screen > 600 ? "right" : "center",
                  fontFamily: "Champagne",
                }}
              >
                Con esta propuesta Diana podrá pagar su crédito en menos tiempo,
                y con más aportes a capital,
                <span style={{ color: "#77b516" }}>
                  {" "}
                  AHORRANDO MUCHO DINERO{" "}
                </span>
                que se iría a intereses
              </div>
            </div>
            <div style={{ textAlign: screen > 600 ? "right" : "center" }}>
              <motion.h1
                initial={{ y: -10 }}
                animate={{
                  y: 10,
                  transition: {
                    duration: 1,
                    ease: "easeIn",
                    repeat: Infinity,
                    repeatType: "reverse",
                  },
                }}
              >
                <Link className="link" to="/slide5">
                  &#8659;
                </Link>
              </motion.h1>
            </div>
          </Grid>
        </Grid>
      </div>
    </motion.div>
  );
};

export default Slide4;
