import React, { useEffect } from "react";
import { useSpring, animated } from "react-spring";
import "../css/statics.css";
import AOS from "aos";
import "aos/dist/aos.css";

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10 + 20}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 6}px,${y / 8 + 220}px,0)`;
const trans3 = (x, y) => `translate3d(${x / 6 - 120}px,${y / 6 + 180}px,0)`;
const trans4 = (x, y) => `translate3d(${x / 3.5 + 100}px,${y / 3.5 + 100}px,0)`;

const Statics = ({ screen }) => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  });
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  return (
    <div
      class="containerStatics"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <animated.div
        class="card31"
        style={{
          transform: props.xy.interpolate(trans1),
          marginTop: screen > 600 ? 0 : -480,
        }}
        data-aos="fade-left"
        data-aos-offset="400"
        data-aos-duration="400"
      />
      <animated.div
        class="card33"
        style={{
          transform: props.xy.interpolate(trans3),
          marginTop: screen > 600 ? 0 : -480,
        }}
        data-aos="zoom-out"
        data-aos-offset="450"
        data-aos-duration="500"
      />
      <animated.div
        class="card32"
        style={{
          transform: props.xy.interpolate(trans2),
          marginTop: screen > 600 ? 0 : -480,
        }}
        data-aos="zoom-out"
        data-aos-offset="550"
        data-aos-duration="500"
      />
      <animated.div
        class="card34"
        style={{
          transform: props.xy.interpolate(trans4),
          marginTop: screen > 600 ? 0 : -480,
        }}
        data-aos="zoom-out"
        data-aos-offset="550"
        data-aos-duration="500"
      />
    </div>
  );
};

export default Statics;
