import React from "react";
import { motion } from "framer-motion";

const svgVariants = {
  hidden: { y: "100px" },
  show: {
    y: "-130px",
    transition: {
      delay: 1,
      duration: 3,
      ease: "easeInOut",
    },
  },
};
const pathVariants = {
  hidden: {
    pathLength: 0,
  },
  show: {
    pathLength: 1,
    transition: {
      delay: 1,
      duration: 6,
      ease: "easeInOut",
    },
  },
};
const PagaMenos = () => {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16256 2370.66"
      id="pagamenosSvg"
      variants={svgVariants}
      initial="hidden"
      animate="show"
    >
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M7603.06 1942.63l-84.67 0 0 -1650.98 25.94 41.79c109.96,190.84 284.06,535.23 387.29,704.91l357.24 650.29c16.74,34.02 42.22,64.79 50.8,101.6l167.69 -281.04c19.56,-32.14 36.34,-63.59 55.15,-97.25l623.82 -1128.77 0 1659.45 -84.66 0 0 -1312.32c-17.73,12.99 -39.7,54.5 -50.85,76.16 -15.73,30.54 -28.94,49.93 -45.13,81.86 -16.27,32.07 -27.84,50.35 -45.35,81.66 -17.12,30.61 -30.32,56.46 -48.52,86.95l-537.78 960.8c-15.76,29.68 -1.03,24.46 -34.37,33.36 -9.06,-33.92 -151.95,-276.06 -180.5,-327.5l-229.48 -413.98c-17.71,-29.68 -28.44,-53.08 -45.58,-81.42l-281.04 -497.89 0 1312.32zm-135.47 42.34l177.8 0 0 -1176.86c5.13,5.91 2.91,2.09 11.65,13.75 8.43,11.24 7.78,10.41 13.55,20.32l279.22 499.71c15.78,27.29 29.12,52.7 45.32,81.67l260.24 467.89c14.2,24.57 27.56,50.67 42.34,76.2 15.58,26.93 33.61,49.25 41.95,85.05l16.93 0c8.39,-36.02 99.89,-183.61 124.15,-231.44l556.11 -993.29c10.18,-17.14 4.23,-8.61 14.01,-19.86l0 1176.86 177.8 0 0 -1879.58 -326.63 587.77c-11.71,19.61 -15.39,30.31 -26.28,49.92 -11.68,21.04 -17.58,27.48 -28.2,48l-383.74 699.98c-22.89,45.13 -98.69,169.42 -107.22,206.04 -33.33,-22.32 -38.78,-56.82 -59.64,-92.75l-191.65 -350.22c-11.79,-21.1 -17.53,-27.46 -28.16,-48.04 -18.47,-35.77 -34.41,-60.31 -53.5,-98.89l-468.57 -852.23c-20.48,-38.39 -65.14,-133.21 -87.48,-149.58l0 1879.58z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M3234.26 1079.04c0,156.33 16.22,237.52 71.14,369.13 62.47,149.68 236.7,323.12 386.05,392.88 363.29,169.72 809.9,60.77 1041.44,-287.84 32.29,-48.62 99.9,-162.26 101.57,-237.1l-643.47 0 0 -84.67 753.54 0c-31.7,380.93 -427.37,770.46 -855.14,770.46 -165.09,0 -285.56,-14.18 -426.27,-81.73 -55.88,-26.82 -150.01,-84.74 -194.01,-127.72l-155.09 -166.63c-224.18,-310.91 -220.39,-733.81 4.78,-1046.43 14.74,-20.46 31.95,-38.26 49.67,-60.4 111.34,-139.11 301.23,-247.08 481.98,-288.48 487.62,-111.7 970.67,205.25 1077.14,662.27l-93.13 0c-3.07,-137.89 -182.82,-353.42 -302.28,-442.78 -249.6,-186.68 -581.98,-202.89 -863.4,-62.5 -233.3,116.38 -434.52,391.12 -434.52,691.54zm-135.46 25.4c0,148.98 33.05,266.55 83.41,390.72 83.42,205.66 283.73,378.51 475.26,473 327.92,161.77 782.14,83.38 1039.57,-171.41 150.52,-148.96 298.29,-377.78 298.29,-607.64l-846.67 0 0 177.8 626.53 0c-8.63,17.98 -12.41,32.86 -22.46,53.73 -56.45,117.25 -129.81,217.25 -236.24,297.16 -59.83,44.92 -120.37,82.05 -197.35,107.45 -39.6,13.07 -71.34,24.75 -119.47,32.92 -111.93,19.01 -192.33,23.23 -304.52,-0.69 -197.39,-42.1 -336.71,-133.7 -459.05,-286.02 -23.89,-29.74 -53.73,-77.47 -72.14,-114.12 -84.3,-167.9 -78.9,-240.78 -78.9,-420.63 0,-190.69 109.95,-359.31 234.83,-484.84 267.86,-269.23 738.16,-279.91 1024.82,-29.74 37.32,32.57 55.82,42.81 86.63,82.71 71.19,92.22 158.61,210.88 169.25,338.74 193.64,0 232.56,52.95 123.5,-208.16 -43.59,-104.34 -115.25,-203.14 -189.1,-276.56 -220.41,-219.12 -398.4,-294.21 -730.26,-294.21 -485.86,0 -905.93,442.45 -905.93,939.79z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M9618.12 1087.51l1143 0 0 84.67 -1143 0 0 677.32 1227.67 0 0 84.67 -1312.34 0 0 -1659.45 1312.34 0 0 84.66 -1227.67 0 0 728.13zm-135.46 897.46l1413.93 0 0 -177.8 -1227.67 0 0 -592.66 1143 0 0 -177.8 -1143 0 0 -634.99 1227.67 0 0 -177.8 -1413.93 0 0 1761.05z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M11277.59 1934.17l-84.67 0 0 -1684.85 82.65 86.68c18.12,19.48 19.75,27.31 37.93,46.73l162.39 184.74c17,21.97 27.95,28.47 44.72,48.42l851.22 969.1c35.7,40.4 83.35,109.65 124.95,137.51l0 -1447.78 84.67 0 0 1676.38c-25.96,-17.39 -93.64,-106.28 -120.61,-133.39l-444.15 -504.11c-17.08,-20.31 -18.35,-26.32 -38.36,-46.3l-84.71 -93.09c-17.29,-19.46 -18.71,-26.68 -38.09,-46.58l-122.73 -139.73c-92.27,-92.17 -264.03,-307.84 -364.13,-414.8 -18.06,-19.3 -24.72,-23.6 -40.22,-44.45 -15.94,-21.45 -21.46,-34.42 -50.86,-42.27l0 1447.79zm-127 50.8l177.8 0 0 -1380.06c32.37,21.69 201.52,230.21 243.32,273.15 19.76,20.3 21.52,21.54 39.65,45.01 68.59,88.78 297.06,324.37 368.96,418.44l606.01 689.38c19.24,25.11 20.76,30.36 45.92,47.21l0 -1854.18 -177.8 0 0 1380.05c-31.81,-21.3 -160.89,-180.65 -205.41,-226.38 -19.38,-19.91 -20.8,-27.13 -38.09,-46.58l-732.21 -834.11c-29.75,-31.79 -50.82,-56.98 -80.57,-88.77 -46.08,-49.24 -210.48,-252.5 -247.58,-277.34l0 1854.18z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M15324.65 460.98c0,108.12 150.59,173.44 228.47,211.79 258.39,127.26 660.53,248.73 660.53,685.67 0,344.53 -283.07,643.46 -618.07,643.46 -215.08,0 -357.74,-56.88 -489.1,-188.23 -117.32,-117.33 -173.84,-256.91 -188.23,-429.83l84.66 0c40.69,174.64 14.35,191.07 159.44,365.5 69.43,83.46 239.84,167.89 365.5,167.89 112.69,0 180.02,-0.65 285.25,-53.4 30.9,-15.49 57.06,-31.25 83.86,-51.62 16.76,-12.73 20.97,-19.45 36.11,-31.62 14.34,-11.52 24.3,-16.33 37.27,-30.46 13.73,-14.96 16.35,-21.71 29.7,-38.03 34.76,-42.48 55.14,-70.51 79.36,-123.84 98.66,-217.3 48.78,-449.37 -134.56,-612.94 -77.97,-69.56 -278.65,-163 -379.55,-204.65 -60.45,-24.94 -138.54,-65.96 -189.44,-98.43 -259.4,-165.45 -92.76,-473.72 126.6,-473.72 104.78,0 216.84,16.31 270.93,118.53 -23.98,2 -57.82,16.58 -76.2,25.4 -75.75,-50.72 -79.23,-59.27 -186.27,-59.27 -98.76,0 -186.26,96.52 -186.26,177.8zm-127 -16.93c0,93.34 5.76,111.83 63.89,190.1 41.09,55.33 125.09,99.31 187.33,134.41 28.13,15.85 46.69,20.13 76.19,33.87 281.58,131.2 553.12,220.02 553.12,572.94 0,76.68 -19.6,145.79 -45.03,200.5 -24.62,52.97 -63.63,109.44 -104.96,149.05 -202.93,194.46 -496.99,199.12 -715.7,8.14 -109.44,-95.55 -158.78,-239.4 -158.78,-391.55l-177.79 0c0,172.42 32.54,295.8 131.71,427.08 48.58,64.33 99.67,117.26 167.94,162.26 202.93,133.77 430.9,159.99 662.12,67.62 73.19,-29.24 197.4,-115.94 245.39,-177.93 133.85,-172.88 172.9,-248.15 172.9,-495.97 0,-254.02 -179.2,-432.38 -342.14,-529.92 -230.74,-138.13 -692.62,-250.78 -490.02,-429.22 63.85,-56.24 198.06,-58.02 247.96,36.29 58.93,-13.73 99.85,-45.05 160.87,-59.27 -1.3,-58.28 -60.02,-110.55 -105.19,-140.35 -126.21,-83.25 -315.54,-66.57 -421.87,28.16 -54.48,48.53 -107.94,114.41 -107.94,213.79z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M5139.26 1934.17l-93.13 0 835.42 -1611.43c15.86,-29.47 46.68,-86.14 53.58,-115.75 21.61,15.83 17.55,17.6 31.13,45.06l250.99 485.61c20.44,40.91 38.03,67.16 59.37,109.96l222.2 429.73c42.47,70.92 70.19,146.48 110.93,219.27 31.36,56.02 214.36,401.16 222.84,437.55l-93.13 0c-9.54,-40.94 -33.36,-67.78 -50.91,-101.49l-160.76 -321.84 -1168.4 0 -220.13 423.33zm-169.33 50.8l203.2 0c8.82,-37.87 130.54,-261.05 166.51,-333.02 14.59,-29.2 38.32,-98.78 70.55,-98.78l1066.8 0c27.93,0 108.21,176.26 124.22,205.98 41.05,76.23 73.5,151.45 112.85,225.82l194.73 0c-4.39,-52.76 -585.67,-1144.92 -660.33,-1295.45 -20.19,-40.7 -40.09,-71.36 -62.16,-115.65l-121.37 -234.23c-25.87,-52.17 -95.04,-208.78 -129.8,-234.25 -7.46,31.98 -40.53,92.07 -57.63,120.16 -23.32,38.29 -41.19,77.47 -60.91,116.9 -173.69,347.39 -371.56,706.49 -544.67,1055.52l-183.52 349.87c-19.62,39.56 -39.45,79.06 -59.21,118.6 -14.08,28.18 -53.38,93.27 -59.26,118.53z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M1354.67 1934.17l-93.14 0c7.23,-31.01 36.13,-82.2 50.69,-110.18l282.22 -539.04c40.74,-72.78 68.46,-148.34 110.93,-219.27 54.63,-91.22 418.89,-833.25 453.63,-858.69l663.18 1292.6c20.32,37.74 37.47,66.33 56.48,104.38l112.93 217.28c19.92,40.23 36.74,75.74 56.41,112.92l-93.14 0c-74.05,-139.95 -145.8,-282.85 -220.13,-423.33l-1168.4 0c-12.84,55.13 -199.51,377.79 -211.66,423.33zm-169.34 50.8l194.73 0c38.9,-73.52 71.32,-143.35 112.78,-217.42 30.34,-54.19 93.09,-214.38 124.29,-214.38l1066.8 0c32.14,0 54.66,66.96 67.73,93.13 36.38,72.83 158.58,292.49 169.34,338.67l203.2 0c-11.28,-48.42 -646.79,-1251.21 -725.31,-1408.28l-239.89 -471.3c-31.14,22.81 -232.02,430.16 -248.36,462.84l-666.03 1289.75c-10.54,21.18 -19.26,39.95 -32.3,60.83 -14.89,23.85 -24.15,32.18 -26.98,66.16z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M12877.78 1112.91c0,-322.3 137.02,-529.53 323.62,-692.38 302.91,-264.34 760.49,-281.46 1085.32,-56.15l125.78 102.82c278.48,278.27 340.05,648.84 188.99,1014.75 -53.76,130.22 -182.05,267.34 -292.83,350.63 -300.79,226.13 -755.28,228.7 -1053.52,3.63 -51.57,-38.93 -72.66,-58.79 -117.01,-103.12 -165.6,-165.57 -260.35,-368.14 -260.35,-620.18zm897.47 939.79c497.37,0 869.52,-355.93 943.72,-808.87 6.21,-37.91 2.7,-72.19 8.41,-110.7 21.63,-145.75 -57.91,-413.63 -151.29,-541.65 -18.52,-25.39 -31.36,-42.94 -50.76,-67.77 -79.99,-102.38 -205.22,-205.22 -321.07,-263.13 -311.36,-155.64 -704.12,-124.39 -985.5,84.18 -73.23,54.27 -118.93,100.68 -177.53,169.61 -97.54,114.73 -159.78,250.01 -192.28,400.37 -8.53,39.45 -23.09,174.8 -18.67,211.25 17.74,146.39 15.78,238.1 86.95,379.27 155.55,308.57 458.59,547.44 858.02,547.44z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M728.13 1468.51l-601.13 0 0 465.66 -84.67 0 0 -1659.45 702.74 0c123.87,0 240.72,45.21 330.32,109.93 18.06,13.05 23.78,21.27 41.02,35.18 17.67,14.26 24.48,16.17 41.68,34.53 100.77,107.62 178.32,275.83 169.98,420.21 -11.7,202.61 -92.87,368.56 -252.68,484 -29.55,21.34 -58.79,39.16 -95.96,56.44 -66.71,31 -156.9,53.5 -251.3,53.5zm-728.13 516.46l177.8 0 0 -465.66 482.6 0c220.46,0 382.2,-55.35 493.31,-167.1 157.62,-158.53 217.89,-263.74 217.89,-510.23 0,-195.1 -93.19,-324.72 -209.51,-442.42 -107.56,-108.83 -262.31,-175.64 -476.29,-175.64l-685.8 0 0 1761.05z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M13013.25 1155.24c0,-155.3 2.68,-242.54 73.28,-383.91 122.58,-245.45 380.71,-428.88 663.32,-428.88 245.05,0 422.24,69.55 565.23,213.71 123.65,124.66 230.64,303.23 230.64,497.48 0,208.52 -18.91,338.3 -145.05,506.89 -37.6,50.25 -137.26,146.64 -190.06,182.47 -215.86,146.46 -495.74,169.77 -736.65,69.13 -239.81,-100.17 -460.71,-376.86 -460.71,-656.89zm-50.8 -50.8c0,444.07 374.16,812.79 778.93,812.79 266.43,0 436.44,-59.91 620.19,-243.41 244.92,-244.59 299.46,-587.29 161.26,-910.55 -34.23,-80.07 -74.73,-123.72 -122.23,-182.57 -48.9,-60.57 -42.64,-50.45 -97.79,-96.94 -237.42,-200.14 -578.12,-250.65 -854.99,-121.6 -289.93,135.14 -485.37,410.58 -485.37,742.28z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M762 1333.04l-584.2 0 0 -931.32 499.53 0c168.9,0 267.88,41.91 361.61,120.98 148,124.86 192.35,343.92 118.75,528.7 -37.95,95.27 -82.58,136.2 -148.13,190.54 -61.53,51 -157.95,91.1 -247.56,91.1zm-635 50.8l609.6 0c397.34,0 685.22,-482.14 370.12,-861.18 -68.69,-82.62 -221.92,-163.28 -344.72,-163.28l-635 0 0 1024.46z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M5935.13 494.85c32.64,21.86 203.13,372.21 234.24,434.62l231.42 445.9 -914.4 0 392.25 -767.67c20.96,-38.92 45.94,-67.61 56.49,-112.85zm-524.94 931.32l1066.8 0c-7.22,-31 -14.99,-37.67 -30.27,-62.86 -14.44,-23.81 -22.06,-41.75 -34.64,-66.96 -23.33,-46.74 -41.45,-83.18 -64.81,-129.92l-200.48 -383.72c-21.22,-42.44 -40.88,-86.58 -65.02,-129.71 -29.33,-52.39 -117.74,-208.38 -129.71,-259.75l-16.93 0c-8.96,38.45 -42.29,84.15 -64.93,129.81l-364.09 702.7c-13.5,25.05 -21.06,42.23 -33.83,67.77 -18.06,36.13 -53.87,97.36 -62.09,132.64z"
      />
      <motion.path
        variants={pathVariants}
        class="fil0"
        d="M2159 494.85l448.73 880.52 -914.4 0c45.98,-86.89 431.72,-855.65 465.67,-880.52zm-541.87 931.32l1066.8 0c-4.1,-49.32 -507.64,-968.17 -524.93,-1032.92 -38.95,28.54 -344.85,663.51 -406.78,770.08 -48.84,84.06 -88.89,175.54 -135.09,262.84z"
      />
    </motion.svg>
  );
};

export default PagaMenos;
