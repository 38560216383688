import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "./pages/Home";
import Slide1 from "./pages/Slide1";
import Slide2 from "./pages/Slide2";
import Slide3 from "./pages/Slide3";
import Slide4 from "./pages/Slide4";
import Slide5 from "./pages/Slide5";
import Slide6 from "./pages/Slide6";
import "./App.css";

//icons
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

//desing
import { Fab } from "@material-ui/core";

const App = () => {
  const location = useLocation();
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(window.innerWidth);
    console.log(window.innerWidth);
  }, []);
  return (
    <div className="App">
      <div
        style={{
          zIndex: 200,
          position: "fixed",
          bottom: 25,
          right: 25,
        }}
      >
        <a
          href="https://api.whatsapp.com/send/?phone=573158173939&text=Hola%2C+Necesito+mas+informaci%C3%B3n.&app_absent=0"
          target="_blank"
        >
          <Fab
            color="primary"
            aria-label="add"
            size="large"
            style={{ background: "#77b516", color: "white" }}
          >
            <WhatsAppIcon />
          </Fab>
        </a>
      </div>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.key}>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/slide1">
            <Slide1 screen={width} />
          </Route>
          <Route path="/slide2">
            <Slide2 screen={width} />
          </Route>
          <Route path="/slide3">
            <Slide3 screen={width} />
          </Route>
          <Route path="/slide4">
            <Slide4 screen={width} />
          </Route>
          <Route path="/slide5">
            <Slide5 screen={width} />
          </Route>
          <Route path="/slide6">
            <Slide6 screen={width} />
          </Route>
        </Switch>
      </AnimatePresence>
    </div>
  );
};

export default App;
