import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  LinearProgress,
} from "@material-ui/core";
//apis
import { sendEmail } from "../api/sendEmail";

//img
import DoneTask from "../multimedia/img/png/doneTask.png";
import ErrorTask from "../multimedia/img/png/errorTask.png";

const containerVariants = {
  hidden: {
    opacity: 0,
    y: "50vh",
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
    },
  },
  back: {
    y: "+100vh",
    transition: { ease: "easeInOut", duration: 0.8 },
  },
};

const Slide6 = ({ screen }) => {
  const [dataCredit, changeDataCredit] = useState({
    nameHolder: "",
    phoneHolder: "",
    emailHolder: "",
  });

  const [errors, setErrors] = useState({
    nameHolder: false,
    phoneHolder: false,
    emailHolder: false,
  });

  const [loading, setLoading] = useState(false);

  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);

  const changeData = (e) => {
    const { name, value } = e.target;

    changeDataCredit((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const sendInformation = async () => {
    setLoading(true);

    let name,
      phone,
      email = false;
    if (dataCredit.nameHolder === "") {
      name = true;
    }
    if (dataCredit.phoneHolder === "") {
      phone = true;
    }
    if (dataCredit.emailHolder === "") {
      email = true;
    }

    setErrors({
      nameHolder: name,
      phoneHolder: phone,
      emailHolder: email,
    });

    if (!name && !phone && !email) {
      let data = {
        to: "gerencia@oikonomos.com.co",
        subject: `NUEVA INSCRIPCIÓN DE REDUCCIÓN DE CREDITO, ${dataCredit.nameHolder}`,
        html: `<h1 style="color: #77b516;">PAGA MENOS INTERESES</h1>
				  <p>&nbsp;</p>
				  <p>Te informa que se genero una nueva petición de reducción de credito desde pagamenosinterses.com:</p>
				  <p>&nbsp;</p>
				  <table class="editorDemoTable" style="width: 488px; height: 270px;">
				  <tbody>
				  <tr style="height: 18px;">
				  <td style="width: 227px; height: 18px;">Nombre Cliente</td>
				  <td style="width: 245px; height: 18px;">${dataCredit.nameHolder}</td>
				  </tr>
				  <tr style="height: 18px;">
				  <td style="width: 227px; height: 18px;">Numero de contacto</td>
				  <td style="width: 245px; height: 18px;">${dataCredit.phoneHolder}</td>
				  </tr>
				  <tr style="height: 18px;">
				  <td style="width: 227px; height: 18px;">Correo de contacto</td>
				  <td style="width: 245px; height: 18px;">${dataCredit.emailHolder}</td>
				  </tr>
				  </tbody>
				  </table>
				  <p>&nbsp;</p>
				  <p>&nbsp;</p>
				  <p><strong>© 2021 Copyright: OIKONOMOS SOLUCIONES FINANCIERAS S.A.S!</strong></p>
				  <p><i>Soporte Técnico</i><br><i>3209424973</i></p>
				  <p><strong>Por favor, no responder a este correo. :D</strong></p>`,
      };
      await sendEmail(data).then((dataServer) => {
        if ((dataServer.data = "Sended")) {
          setDone(true);
          setLoading(false);
        } else {
          setError(true);
          setLoading(true);
        }
      });
    }
  };

  return (
    <motion.div
      className="about-container"
      variants={containerVariants}
      initial="hidden"
      animate="show"
      exit="back"
    >
      <div
        style={{
          display: "grid",
          alignItems: "center",
        }}
        data-aos="fade-up"
        data-aos-offset="500"
      >
        <div style={{ width: "100%", textAlign: "center" }}>
          <motion.h1
            initial={{ y: -10 }}
            animate={{
              y: 10,
              transition: {
                duration: 1,
                ease: "easeIn",
                repeat: Infinity,
                repeatType: "reverse",
              },
            }}
          >
            <Link className="link" to="/">
              &#8657;
            </Link>
          </motion.h1>
        </div>
        <Paper style={{ width: "80%", margin: "auto" }}>
          {loading ? <LinearProgress /> : ""}
          {done ? (
            <Grid container spacing={3} style={{ padding: 20 }}>
              <Grid item xs={12} md={12}>
                <Typography
                  color="textPrimary"
                  variant="h6"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  INSCRIPCIÓN REALIZADA
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <div style={{ textAlign: "center", width: "100%" }}>
                  <img
                    src={DoneTask}
                    alt="done-task"
                    style={{ width: "250px", height: "250px" }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontFamily: "Champagne",
                    fontSize: screen > 600 ? "2rem" : "1rem",
                  }}
                >
                  Tu información fue recibida exitosamente. Pronto te contactara
                  un asesor.
                </div>
              </Grid>
            </Grid>
          ) : error ? (
            <Grid container spacing={3} style={{ padding: 20 }}>
              <Grid item xs={12} md={12}>
                <Typography
                  color="textPrimary"
                  variant="h6"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  FALLO LA INCRIPCIÓN
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <div style={{ textAlign: "center", width: "100%" }}>
                  <img
                    src={ErrorTask}
                    alt="done-task"
                    style={{ width: "250px", height: "250px" }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontFamily: "Champagne",
                    fontSize: screen > 600 ? "2rem" : "1rem",
                  }}
                >
                  Tu información no fue recibida. Intenta nuevo o contactanos
                  por Whatsapp. Lamentamos las molestias.
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3} style={{ padding: 20 }}>
              <Grid item xs={12} md={12}>
                <Typography
                  color="textPrimary"
                  variant="h6"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  ¡ME INTERESA!
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  error={errors.nameHolder}
                  label="Nombre Completo"
                  variant="outlined"
                  name="nameHolder"
                  fullWidth
                  value={dataCredit.nameHolder}
                  onChange={changeData}
                  type="text"
                  helperText={errors.nameHolder ? "Información necesaria." : ""}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  error={errors.phoneHolder}
                  label="Numero de Contacto"
                  variant="outlined"
                  name="phoneHolder"
                  fullWidth
                  value={dataCredit.phoneHolder}
                  onChange={changeData}
                  type="number"
                  helperText={
                    errors.phoneHolder ? "Información necesaria." : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  error={errors.emailHolder}
                  label="Correo de Contacto"
                  variant="outlined"
                  name="emailHolder"
                  fullWidth
                  value={dataCredit.emailHolder}
                  onChange={changeData}
                  type="email"
                  helperText={
                    errors.emailHolder ? "Información necesaria." : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  color="primary"
                  variant="outlined"
                  fullWidth
                  onClick={sendInformation}
                >
                  ENVIAR INFORMACION
                </Button>
              </Grid>
            </Grid>
          )}
          {loading ? <LinearProgress /> : ""}
        </Paper>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: 100,
          textAlign: "center",
          fontFamily: "roboto",
        }}
      >
        © Copyright Oikonomos Soluciones Financieras {new Date().getFullYear()}
      </div>
    </motion.div>
  );
};

export default Slide6;
