import React, { useEffect } from "react";
import { useSpring, animated } from "react-spring";
import "../css/women.css";

import AOS from "aos";
import "aos/dist/aos.css";

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10 + 110}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 6 - 100}px,${y / 8}px,0)`;
const trans3 = (x, y) => `translate3d(${x / 6 - 100}px,${y / 6 + 30}px,0)`;
const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5 + 100}px,0)`;

const Women = ({ screen }) => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  });
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));
  return (
    <div
      class="containerWomen"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <animated.div
        class="card1"
        style={{
          transform: props.xy.interpolate(trans1),
          marginTop: screen < 600 ? -500 : 0,
          zIndex: -3,
        }}
        data-aos="fade-left"
        data-aos-duration="400"
      />
      <animated.div
        class="card4"
        style={{
          transform: props.xy.interpolate(trans4),
          marginTop: screen < 600 ? -500 : 0,
		  zIndex: -2,
        }}
        data-aos="zoom-out"
        data-aos-duration="500"
      />
      <animated.div
        class="card2"
        style={{
          transform: props.xy.interpolate(trans2),
          marginTop: screen < 600 ? -500 : 0,
		  zIndex: -1,
        }}
        data-aos="zoom-out"
        data-aos-duration="500"
      />
      <animated.div
        class="card3"
        style={{
          transform: props.xy.interpolate(trans3),
          marginTop: screen < 600 ? -500 : 0,
        }}
        data-aos="zoom-out"
        data-aos-duration="500"
      />
    </div>
  );
};

export default Women;
